.container {
  height: 80vh !important;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
}

.title {
  color: var(--text-tertiary);
  text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
  font-style: normal;
}

.subTitle {
  color: var(--text-tertiary);
  text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
  font-style: normal;
}

@media screen and (min-width: 600px) {
  .container {
    height: 49.25rem !important;
  }

  .title {
    font-size: 6.5rem;
    font-weight: 700;
    line-height: 7rem;
  }

  .subTitle {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 3.85rem;
  }
}
