.checkboxContainer {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  margin: 1rem 1rem;
  margin-bottom: 2rem;
}

.checkbox {
  margin-top: 3px;
  margin-right: 1rem;
  cursor: pointer;
  accent-color: black;
}

.checkboxText {
  font-size: 14px;
  line-height: 1.4;
}
