.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem 1rem;
  height: calc(var(--top-bar-height) + 2.5rem);
}

.sejfaLogo {
  width: 4rem;
  cursor: pointer;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .container {
    padding: 0.75rem 1.5rem;
    flex-direction: row;
    height: var(--top-bar-height);
    justify-content: space-between;
  }

  .sejfaLogo {
    width: 5.5rem;
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 1rem 2.5rem;
  }
}
