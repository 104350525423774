.offersButton {
  width: auto;
  border: 2px solid var(--button-stroke-primary) !important;
  background-color: var(--button-background-secondary);
  padding: 0.5rem 1rem;
  color: white;
}

.offersButton:active {
  border-color: var(--button-pressed-primary);
}

.hidden {
  display: none;
}
